<template>
    <div class="page">
        <app-header :navIndex="3"></app-header>
        <banner :data="banner"></banner>
        <section class="section-container top-box mt-md-12 pt-md-12 pb-md-12">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="d-flex flex-column flex-md-row justify-md-space-between">
                <div class="col-md-2 d-flex flex-column pa-0 mr-md-5 mb-12 mb-md-0 order-1 order-md-0">
                    <div class="type-box white hidden-sm-and-down">
                        <div class="type text-center" v-for="(item,index) in typeList" :key="index"
                             :class="`${activeIndex==item.id?'type-selected ':''}${index==0?' type-radius':''}`"
                             @click="activeIndex=item.id">
                            {{item.title}}
                        </div>
                    </div>
                    <div class="code-box white mt-md-5 pa-md-5 d-flex flex-column align-center">
                        <v-img contain :src="code.img"></v-img>
                        <div class="desc text-center mt-md-1">{{code.title}}</div>
                        <div class="desc text-center">{{code.desc}}</div>
                    </div>
                </div>
                <div class="flex item-box order-0 order-md-1">
                    <div class="pa-md-12 pa-5 white" v-for="(item,index) in list" :key="index"
                         :class="`${index==0?'item-top-radius ':''}${index==list.length-1?'item-bottom-radius':''}`">
                        <div class="item-title font-weight-bold">{{item.position}}</div>
                        <div class="item-type mt-4 mb-5 font-weight-medium">{{item.type}}</div>
                        {{$i18n.t('common.join.positionDes')}}
                        <div class="desc mt-4 mt-md-1 mb-7 mb-md-8" v-html="item.desc"></div>
                        {{$i18n.t('common.join.positionRem')}}
                        <div class="desc mt-4 mt-md-1 mb-5 mb-md-6" v-html="item.requirement"></div>
                        <base-btn class="more-btn">{{$i18n.t('common.join.send')}}
                            <span class="iconfont iconjiantou"></span>
                        </base-btn>
                    </div>

                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import Banner from "../../../components/base/banner";
    import AppHeader from "../../../components/core/header/index";
    export default {
        components: {
            AppHeader,
            Banner},
        data(){
            return {
                banner: {
                    imgUrl: 'employee/join/school/bg.jpg',
                    wrapImgUrl: 'employee/join/school/bg_one.jpg',
                    title: '',
                    enTitle: ''
                },
                typeList: [{
                    id: 1, title: '招聘职位'
                }/*, {
                 id: 2, title: '招聘流程'
                 }, {
                 id: 3, title: '空中宣讲'
                 }, {
                 id: 4, title: '答疑专区'
                 }*/],
                code: {
                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/employee/join/school/code.jpg'),
                    title: '关注ASC精品酒业',
                    desc: '了解更多动态'
                },
                list: [{
                    position: '销售管理培训生',
                    type: '上海 | 校招',
                    desc: '1.Follow up Manager arrangement of daily sales operation including sales plan/team target acchievement/promotion plan to develop more sales value.' +
                    '<br>2.Using sales skills anf product konwledge to sell and directly responsible for on trade accounts in order to achieve monthly sales target of Cross Channel',
                    requirement: '1. Updates job knowledge by participating in educational improvement opportunities; reading sales and marketing publications; maintaining personal networks.' +
                    '<br>2. Accomplishes sales and organization mission by completing related results as needed.' +
                    '<br>3. Receives training and performs duties for sales and marketing process by completing assignments and rotation.' +
                    '<br>4. Study more wine knowledge and be a wine expert.' +
                    '<br>5. Work with sales to act as an executive role.' +
                    '<br>6. Track the sales performance, and provide the relevant analysis report at any time.' +
                    '<br>7. Collect and prepare monthly market report (including price and competitor’s information).' +
                    '<br>8. Maintain the good relationship with existing clients and develop the new clients Cooperate with the Sales team to find new business opportunities through daily sales information operation.'
                }],
                activeIndex: 1
            }
        },
        created(){
            this.initData()
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "加入我们"
            document.getElementById("bodyId").appendChild(div);
        },
        methods: {
            initData(){
                this.$api.web.getRecruitment({type: 1}).then(res => {
                    if (res.rows.length > 0) {
                        this.list = res.rows
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .page {
        background-color: #FAFAFA;
    }

    .type-box {
        border-top-left-radius: 20px;
        .type {
            height: 69px;
            line-height: 69px;
            font-size: 20px;
            cursor: pointer;
        }
        .type-radius {
            border-top-left-radius: 20px;
        }
        .type-selected {
            background-color: $primary-color !important;
            color: white;
        }
    }

    .code-box {
        border-bottom-left-radius: 20px;
    }

    .desc {
        /* font-size: 14px;
         line-height: 29px;*/
        color: #7E7E7E;
    }

    .item-box {
        font-size: 16px;
        .item-title {
            font-size: 20px;
        }
        .item-type {
            font-size: 18px;
        }
        .item-top-radius {
            border-top-right-radius: 20px;
        }
        .item-top-radius {
            border-bottom-right-radius: 20px;
        }
    }

    @media (max-width: $screen-md) {
        .page {
            background-color: white;
        }
        .desc {
            text-align: left;
        }
        .item-box {
            font-size: 14px;
            .item-title {
                font-size: 18px;
            }
            .item-type {
                font-size: 16px;
            }
        }
    }
</style>
